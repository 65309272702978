//
// page-title.scss
//

.account-pages {
  min-height: 100vh;
}

.page-title-box {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 -27px 0;
  padding: 10px 27px;
  position: relative;
  .page-title-box-group {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #828282;
    padding-bottom: 10px;
  }
  .page-title {
    font-size: 1.05rem;
    margin: 0;
  }

  .breadcrumb {
    padding: 0;
    line-height: 37px;
  }
  .form-check-label {
    font-weight: 400;
  }
}

@media (max-width: 639px) {
  .page-title-box {
    display: block;

    .page-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .page-title-right {
      display: block;
      padding-top: 5px;
    }
  }
}
