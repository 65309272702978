//
// buttons.scss
//

.btn {
	color: $white;

	&:focus {
		box-shadow: none;
	}

	.icon {
		svg {
			width: 1rem;
			height: 1rem;
			margin-left: 0.1rem;
			margin-top: -2px;
		}
	}
}

//
// light button
//
.btn-light,
.btn-white {
	color: $gray-900;
}

.btn-white {
	border-color: $gray-300;

	&:hover,
	&:focus {
		background-color: $gray-300;
		border-color: $gray-400;
	}
}
//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
	font-weight: $font-weight-normal;
	color: $link-color;
	background-color: transparent;
	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
		background-color: transparent;
		border-color: transparent;
	}
	&:focus {
		text-decoration: $link-hover-decoration;
		border-color: transparent;
		box-shadow: none;
	}
	&:disabled {
		color: $btn-link-disabled-color;
		pointer-events: none;
	} // No need for an active state here
}

//
// Button Extra Small Size
//

.btn-xs {
	padding: 0.2rem 0.6rem;
	font-size: 0.75rem;
	border-radius: 0.15rem;
}

// Soft variants
@mixin btn-variant-soft($bg, $color) {
	background-color: rgba($bg, $btn-soft-bg-opacity);
	color: $color;
	border-color: rgba($color, $btn-soft-bg-opacity);

	&:focus {
		box-shadow: none;
	}

	&:hover,
	&:focus {
		background-color: fade-in(rgba($bg, $btn-soft-bg-opacity), 0.05);
		color: $color;
	}
}

@each $color, $value in $theme-colors {
	.btn-soft-#{$color} {
		@include btn-variant-soft($value, $value);
	}
}

.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: var(--theme-color);
	--bs-btn-border-color: var(--theme-color);
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: var(--theme-color);
	--bs-btn-hover-border-color: var(--theme-color);
	--bs-btn-focus-shadow-rgb: 212, 82, 82;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: var(--theme-color);
	--bs-btn-active-border-color: var(--theme-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--theme-color);
	--bs-btn-disabled-border-color: var(--theme-color);
}

.btn-outline-primary {
	--bs-btn-color: var(--theme-color);
	--bs-btn-border-color: var(--theme-color);
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: var(--theme-color);
	--bs-btn-hover-border-color: var(--theme-color);
	--bs-btn-focus-shadow-rgb: 204, 51, 51;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: var(--theme-color);
	--bs-btn-active-border-color: var(--theme-color);
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: var(--theme-color);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--theme-color);
	--bs-gradient: none;
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
		&:focus {
			box-shadow: none;
		}
	}
}

.btn-toggle-bill {
	min-width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	position: relative;

	.btn-hover {
		position: absolute;
		top: 0;
		left: 0;
		margin-top: 0px;
	}

	.menu-hover {
		position: absolute;
		bottom: 0px;
		left: -122px;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		animation-name: DropDownSlide;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		font-size: 0.875rem;
		background-color: white;
		z-index: 1000;
		padding: 4px;

		.menu-item-hover {
			display: block;
			padding: 6px 12px;
		}
	}
}
.select__control.css-13cymwt-control .select__input-container.css-qbdosj-Input,
.select__control.css-t3ipsp-control.select__control--is-focused
	.select__input-container.css-qbdosj-Input {
	margin-top: -10px !important;
}
