//
// tasks.scss
//

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  vertical-align: top;
  margin-bottom: $grid-gutter-width;
  background-color: $card-bg;
  @include border-radius($border-radius-sm);

  &.tasks:not(:last-child) {
    margin-right: 1.25rem;
  }

  .card {
    white-space: normal;
    margin-top: 1rem;
  }

  .task-header {
    background-color: $card-cap-bg;
    padding: 1rem;
    margin: 0 -1rem;
    @include border-radius($border-radius-sm);
  }
}

.task-list-items {
  min-height: 100px;
  position: relative;

  .card {
    cursor: pointer;
  }

  &:before {
    content: "No Tasks";
    position: absolute;
    line-height: 110px;
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
}

// Task add modal
.task-modal-content {
  .form-control-light {
    background-color: lighten($gray-200, 2.5%) !important;
    border-color: lighten($gray-200, 2.5%) !important;
  }
}

// task - gantt
.gantt-task-details {
  min-width: 220px;
}

// task search
.task-search {
  .search-input {
    padding: 4px 0px 4px 20px;
    z-index: 10;
  }
  .icon-search {
    z-index: 11;
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.manager-awb {
  padding-top: 4px;
  padding-bottom: 4px;
}

.search-page-list {
  background-color: #e00e0e;
  border: 1px;
}
