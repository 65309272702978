//
// Google font - IBM Plex Sans
//

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap");

.break-word {
	word-break: break-word;
	text-wrap: wrap;
}
