.form-search-user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 850px;
	border-radius: 6px;
	border: 1px solid #d1d5db;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 42px 10px 0px;
	position: relative;
	max-height: 44px;
	input[type="search"] {
		border: none;
	}
	.form-border {
		position: relative;
		display: flex;
		align-items: center;
		flex: 1;
		&:before {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			height: 18px;
			background-color: #bdbdbd;
		}
	}
	.form-select {
		color: #3c4257;
		width: auto;
		padding: 4px 20px 4px 8px;
		background-position: right 4px center;
		background-image: url("../../../images/icons/icon-down.svg");
		margin-left: 10px;
	}
	.btn-search {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
		background-color: transparent;
		border: none;
		color: #6b7280;
		background-image: url("../../../images/icons/icon-search.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
	}
	& ~ .form-search-btn {
		margin-right: 32px;
		margin-bottom: 16px;
	}
}
.form-search-btn {
	button.btn-action {
		height: 44px;
		width: 44px;
		font-size: 20px;
		padding: 10px;
		position: relative;
		& + button {
			margin-left: 10px;
		}
		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			line-height: 1;
			&::before {
				font-size: 100%;
			}
			&.uil-redo {
				&::before {
					transform: rotateY(180deg);
				}
			}
		}
	}
}
.table-custom {
	margin-bottom: 0;
	font-size: 16px;
	thead {
		background-color: #e6e8ec;
		color: #777e90;
		th {
			font-weight: 400;
			&.sortable {
				&:before,
				&::after {
					content: none;
				}
				span {
					position: relative;
					font-weight: 400;
					&::before,
					&::after {
						content: "";
						position: absolute;
						right: -15px;
						top: 50%;
						transform: translateY(-50%);
						background-image: url("../../../images/icons/arrow-up.svg");
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						width: 6px;
						height: 11px;
					}
					&::after {
						right: -22px;
						background-image: url("../../../images/icons/arrow-up.svg");
						transform: translateY(-50%) rotate(178deg);
						opacity: 0.6;
					}
				}
			}
			&.sorting_desc {
				span {
					&::before {
						opacity: 0.6;
					}
					&::after {
						opacity: 1;
					}
				}
			}
			&:not(.sortable) {
				padding-right: 0.85rem;
			}
		}
	}
	tbody tr {
		vertical-align: middle;
		color: #23262f;
		td {
			color: #23262f;
			text-align: left;
			background-color: white;
            // font-size: 14px;
			.item-info-customer {
				text-align: center;
				a {
					color: #6b7280;
					font-size: 16px;
					margin: 0 5px;
				}
			}
		}
	}
	&.table-customer {
		tr {
			td {
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					font-weight: 700;
				}
			}
		}
	}
}

.table-user-bottom {
	padding: 10px;
	.form-select {
		padding: 4px 36px 4px 8px;
		background-position: right 10px center;
	}
}

.pagination {
	.page-item {
		&.active {
			.page-link {
				color: #b62222;
				border: 1px solid #b62222;
			}
		}
		.page-link {
			min-width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			color: #23262f;
			border-radius: 4px !important;
			cursor: pointer;
		}
	}
}
.card-custom {
	&.card-custom {
		.card-body {
			padding: 0;
		}
	}
}

// Popup User

.popup-info {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	font-size: 16px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	-webkit-transform: translate(-110%, 0px);
	-moz-transform: translate(-110%, 0px);
	-ms-transform: translate(-110%, 0px);
	-o-transform: translate(-110%, 0px);
	transform: translate(-110%, 0px);
	&.opened {
		-webkit-transform: translate(0, 0px);
		-moz-transform: translate(0, 0px);
		-ms-transform: translate(0, 0px);
		-o-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}
	.close {
		width: 28px;
		height: 28px;
		background-image: url("../../../images/icons/close-popup.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline-block;
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}
	.code-popup {
		font-size: 24px;
		font-weight: 700;
		line-height: 32px;
		margin-bottom: 50px;
		color: #262626;
		position: relative;
		padding-right: 40px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -25px;
			border-bottom: 1px solid #828282;
			width: 100%;
		}
	}
	.popup-info-inner {
	}
	.title-popup {
		color: #111827;
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
		background-color: #f1f8fe;
		padding: 23px 48px;
		position: relative;
		.close {
			right: 60px;
		}
	}
	.popup-info-inner {
		background-color: #fff;
		padding-top: 32px;
		max-width: 85%;
		position: relative;
		height: 100%;
		z-index: 999;
		overflow-y: scroll;
		.popup-inner {
			padding: 32px 48px;
			.id-customer {
				color: #111827;
				font-size: 24px;
				font-weight: 700;
				line-height: 32px;
				margin-bottom: 32px;
				span.id {
					color: #2f80ed;
					margin-left: 12px;
				}
			}
			.btn-bottom {
				text-align: center;
				margin-top: 20px;
				width: 100%;
			}
		}
		.infor-general {
			padding: 16px;
			border-bottom: 1px solid #e6e8ec;
		}
	}
	.popup-overlay {
		opacity: 0;
		filter: alpha(opacity=0);
		&.active {
			background: rgba(0, 0, 0, 0.5);
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 99;
			min-height: 100vh;
			opacity: 1;
			filter: alpha(opacity=35);
		}
	}
	label.form-label {
		color: #111827;
		margin-bottom: 8px;
	}
	&.popup-info-custom {
		.popup-info-inner {
			padding: 16px;
			.title-popup {
				padding: 14px 16px;
			}
		}
	}
	.info-product {
		border: 1px solid #e6e8ec;
		border-radius: 8px;
		.title {
			background-color: #e6e8ec;
			padding: 14px 16px;
			font-size: 24px;
			font-weight: 700;
			color: #262626;
		}
		form {
			padding: 16px;
		}
	}
}

@media all and (max-width: 1024px) {
	.form-search-user {
		& ~ .form-search-btn {
			margin-right: 16px;
		}
	}
	.popup-info {
		.close {
			width: 20px;
			height: 20px;
		}
		.code-popup {
			font-size: 20px;
			margin-bottom: 40px;
			&::before {
				bottom: -20px;
			}
		}

		@include media-breakpoint-up(sm) {
			.title-popup {
				padding: 16px 24px;
				font-size: 24px;
				.close {
					right: 24px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.title-popup {
				padding: 8px 14px;
				font-size: 16px;
				.close {
					right: 24px;
				}
				.id-customer {
					font-size: 14px !important;
					color: #111827;
					font-weight: 700;
					line-height: 20px;
				}
			}
		}
		.popup-info-inner {
			max-width: 100%;
			.popup-inner {
				padding: 16px 24px;
				.btn-bottom {
					order: 2;
				}
				.list-account {
					order: 3;
				}
			}
			.infor-general {
				padding: 16px 0px;
			}
		}
	}
}

@media all and (max-width: 576px) {
	.info-user {
		.title-info-user {
			font-size: 24px;
			margin-bottom: 20px;
		}
		.info-user-inner {
			padding: 32px 24px 16px;
		}
	}
	.form-search-btn {
		button.btn-action {
			height: 38px;
			width: 38px;
			font-size: 16px;
		}
	}
}
