.wrap-filter {
	margin-bottom: 30px;
	.list-button {
		margin-left: -16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 20px;
		button {
			font-size: 14px;
			color: #c33;
			width: max-content;
			text-align: center;
			height: 32px;
			padding: 0px 10px;
			border-radius: 20px;
			min-width: 110px;
			margin-left: 16px;
			border-color: #c33;
			position: relative;

			&.active {
				color: #fff !important;
			}
			&:hover {
				color: #fff !important;
			}

			&.color-0 {
				border-color: #c33;
				color: #c33;
				&.active {
					background-color: #c33;
				}
				&:hover {
					background-color: #c33;
				}
			}

			&.color-1 {
				border-color: #f2994a;
				color: #f2994a;
				&.active {
					background-color: #f2994a;
				}
				&:hover {
					background-color: #f2994a;
				}
			}
			&.color-2 {
				border-color: #b573c7;
				color: #b573c7;
				&.active {
					background-color: #b573c7;
				}
				&:hover {
					background-color: #b573c7;
				}
			}
			&.color-3 {
				border-color: #70bbfd;
				color: #70bbfd;
				&.active {
					background-color: #70bbfd;
				}
				&:hover {
					background-color: #70bbfd;
				}
			}
			&.color-4 {
				border-color: #4dacc8;
				color: #4dacc8;
				&.active {
					background-color: #4dacc8;
				}
				&:hover {
					background-color: #4dacc8;
				}
			}
			&.color-5 {
				border-color: #365683;
				color: #365683;
				&.active {
					background-color: #365683;
				}
				&:hover {
					background-color: #365683;
				}
			}
			&.color-6 {
				border-color: #ffcb00;
				color: #ffcb00;
				&.active {
					background-color: #ffcb00;
				}
				&:hover {
					background-color: #ffcb00;
				}
			}
			&.color-7 {
				border-color: #4cdaaf;
				color: #4cdaaf;
				&.active {
					background-color: #4cdaaf;
				}
				&:hover {
					background-color: #4cdaaf;
				}
			}
			&.color-8 {
				border-color: #27ae60;
				color: #27ae60;
				&.active {
					background-color: #27ae60;
				}
				&:hover {
					background-color: #27ae60;
				}
			}
			&.color-9 {
				border-color: #b62222;
				color: #b62222;
				&.active {
					background-color: #b62222;
				}
				&:hover {
					background-color: #b62222;
				}
			}
			&.color-10 {
				border-color: #b5cdef;
				color: #b5cdef;
				&.active {
					background-color: #b5cdef;
				}
				&:hover {
					background-color: #b5cdef;
				}
			}
			span {
				display: inline-block;
				position: absolute;
				top: -12px;
				right: -10px;
				border-radius: 4px;
				font-size: 10px;
				padding: 2px 8px;
				line-height: 1;
				border: 1px solid #fff;

				&.color-0 {
					background-color: #c33;
					color: #fff;
				}
	
				&.color-1 {
					color: #fff;
					background-color: #f2994a;
				}
				&.color-2 {
					color: #fff;
					background-color: #b573c7;
				}
				&.color-3 {
					background-color: #70bbfd;
					color: #fff;
				}
				&.color-4 {
					background-color: #4dacc8;
					color: #fff;
				}
				&.color-5 {
					background-color: #365683;
					color: #fff;
				}
				&.color-6 {
					background-color: #ffcb00;
					color: #fff;
				}
				&.color-7 {
					background-color: #4cdaaf;
					color: #fff;
				}
				&.color-8 {
					background-color: #27ae60;
					color: #fff;
				}
				&.color-9 {
					background-color: #b62222;
					color: #fff;
				}
				&.color-10 {
					background-color: #b5cdef;
					color: #fff;
				}
			}
		}
	}

	.form-search-user {
		.select__control {
			width: 100%;
		}
	}
}

.form-search-user.form-search-tracking {
	input[type="search"] {
		border-right: 1px solid #ccc;
		border-radius: 0;
	}
	// input[type="search"]::-webkit-search-cancel-button{
	//     background-image: url(/src/assets/images/icons/close.svg);
	//     background-repeat: no-repeat;
	//     width: 20px;
	//     height: 20px;
	// }
	.styled-select {
		position: relative;
		padding-left: 12px;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			height: 18px;
			background-color: #bdbdbd;
		}
	}
	.form-select {
		width: auto;
		padding: 4px 8px;
	}
	.btn-search {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
		background-color: transparent;
		border: none;
		color: #6b7280;
	}
}

.col-right {
	display: flex;
	align-items: center;
	.wrap-date-range {
		margin-right: 24px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		border: 1px solid #d1d5db;
		min-height: 44px;
		border-radius: 4px;
		position: relative;
		.react-datepicker-wrapper {
			width: 100%;
		}
		input {
			border: none;
			text-align: center;
			position: relative;
		}
		&::before {
			content: "\e8b6";
			position: absolute;
			font-family: unicons;
			z-index: 99;
			right: 7px;
		}
		.react-datepicker {
			span[role="alert"] {
				display: none;
			}
		}
	}

	.date-picker-statistic {
		flex-grow: 1;
		display: flex;
		align-items: center;
		border: 1px solid #d1d5db;
		min-height: 38px;
		border-radius: 6px;
		position: relative;
		.react-datepicker-wrapper {
			width: 100%;
		}
		input {
			border: none;
			text-align: center;
			position: relative;
		}
		&::before {
			content: "\e8b6";
			position: absolute;
			font-family: unicons;
			z-index: 99;
			right: 7px;
		}
		.react-datepicker {
			span[role="alert"] {
				display: none;
			}
		}
	}

	.list-time-select {
		max-width: 170px;
		background-position: right 0.3rem center;
		padding-right: 21px;
		min-height: 44px;
		margin-right: 24px;
	}
}

.group-btn-action {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	position: relative;
	font-weight: bold;
	button {
		margin: 2px;
		min-width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		position: relative;
		&.btn-exploit {
			background: rgba(47, 128, 237, 0.2);
			color: #2f80ed;
			&:hover {
				background: rgba(47, 128, 237, 1);
				color: #fff;
			}
			.title {
				background-color: #2f80ed;
				&::before {
					border-top-color: #2f80ed;
				}
			}
		}
		&.btn-create-order {
			background: rgba(39, 174, 96, 0.3);
			color: #27ae60;
			&:hover {
				background: rgba(39, 174, 96, 1);
				color: #fff;
			}
			.title {
				background-color: #27ae60;
				&::before {
					border-top-color: #27ae60;
				}
			}
		}
		&.btn-send-required {
			background: rgba(47, 128, 237, 0.2);
			color: #2f80ed;
			&:hover {
				background: rgba(47, 128, 237, 1);
				color: #fff;
			}
			.title {
				background-color: #2f80ed;
				color: #fff;
				&::before {
					content: "";
					border-top-color: #2f80ed;
				}
			}
		}

		&.btn-edit-tracking {
			background: #f8e9e9;
			color: #cc3333;
			&:hover {
				background: #cc3333;
				color: #fff;
			}
			.title {
				background-color: #cc3333;
				&::before {
					border-top-color: #cc3333;
				}
			}
		}
		&.btn-delete-tracking {
			background: rgba(242, 153, 74, 0.2);
			color: #f2994a;
			&:hover {
				background: rgba(242, 153, 74, 1);
				color: #fff;
			}
			.title {
				background-color: #f2994a;
				&::before {
					border-top-color: #f2994a;
				}
			}
		}
		&.btn-print {
			background: rgba(47, 128, 237, 0.2);
			color: #2f80ed;
			&:hover {
				background: rgba(47, 128, 237, 1);
				color: #fff;
			}
			.title {
				background-color: #2f80ed;
				&::before {
					border-top-color: #2f80ed;
				}
			}
		}

		span.title {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			display: inline-block;
			top: -42px;
			right: 0;
			padding: 5px 10px;
			z-index: 99;
			background-color: #ad2b2b;
			color: #fff;
			min-width: 120px;
			&::before {
				content: "";
				border-top: 8px solid #ad2b2b;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				position: absolute;
				bottom: -8px;
				right: 10px;
			}
		}
		&:hover,
		&:focus {
			.title {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.btn-update-order {
		color: #2f80ed;
		border: 1px solid #2f80ed;
		border-radius: 4px;
		width: 100%;
		.title {
			color: #2f80ed;
		}
	}
}

.form-search-tracking-multi {
	margin-bottom: 30px;
	border-radius: 6px;
	border: 1px solid #d1d5db;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	display: flex;
	padding: 10px 12px;
	width: max-content;
	max-width: 100%;
	justify-content: space-between;
	min-width: 500px;
	flex-wrap: wrap;
	.list-value-input {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.field-group {
		display: flex;
		align-items: center;
		flex-grow: 1;
		.btn-search {
			background-color: transparent;
			border: none;
			color: #000;
		}
	}
	.keyword-added {
		border: 1px solid #d1d5db;
		border-radius: 4px;
		margin: 5px;
		padding: 4px 8px;
		background-color: white;
		color: #828282;
		.remove-kw {
			margin-left: 4px;
			cursor: pointer;
			color: #828282;
		}
	}

	.remove-all {
		font-size: 20px;
		font-weight: bold;
		color: #000;
		cursor: pointer;
	}
}

.status-tbl {
	.progress-bar {
		height: 5px;
	}
	.name-status {
		// text-align: center;
		align-items: center;
		margin-bottom: 5px;
		white-space: break-spaces;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		width: fit-content;
		span {
			width: 6px;
			height: 6px;
			display: block;
			margin-right: 10px;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.wrap-progress {
		background-color: #f2f2f2;
		border-radius: 60px;
		max-width: 115px;
		overflow: hidden;
	}

	&.dang-den-vn {
		.name-status {
			color: #70bbfd;
			background-color: #70bbfd37;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #70bbfd;
				background-color: #70bbfd;
			}
		}
		.progress-bar {
			background-color: #70bbfd;
		}
	}

	&.da-den-vn {
		.name-status {
			color: #70bbfd;
			background-color: #70bbfd37;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #70bbfd;
				background-color: #70bbfd;
			}
		}
		.progress-bar {
			background-color: #70bbfd;
		}
	}
	&.da-nhap-kho {
		.name-status {
			color: #4dacc8;
			background-color: #4dacc833;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #4dacc8;
				background-color: #4dacc8;
			}
		}
		.progress-bar {
			background-color: #4dacc8;
		}
	}
	&.da-dong-hang {
		.name-status {
			color: #9d4dc8;
			background-color: #9d4dc83a;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #9d4dc8;
				background-color: #9d4dc8;
			}
		}
		.progress-bar {
			background-color: #9d4dc8;
		}
	}
	&.dang-van-chuyen {
		.name-status {
			color: #4dacc8;
			background-color: #4dabc840;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #4dacc8;
				background-color: #4dacc8;
			}
		}
		.progress-bar {
			background-color: #4dacc8;
		}
	}
	&.da-huy-bo {
		.name-status {
			color: #b62222;
			background-color: #e2e2e2;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #b62222;
				background-color: #b62222;
			}
		}
		.progress-bar {
			background-color: #b62222;
		}
	}
	&.huy-bo {
		.name-status {
			color: #cc3333;
			background-color: #dfdede;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #cc3333;
				background-color: #cc3333;
			}
		}
		.progress-bar {
			background-color: #cc3333;
		}
	}
	&.chua-co-loai {
		.name-status {
			color: #2d346f;
			background-color: #2d346f36;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #2d346f;
				background-color: #2d346f;
			}
		}
		.progress-bar {
			background-color: #2d346f;
		}
	}
	&.chua-co-kh {
		.name-status {
			color: #4cdaaf;
			background-color: #4cdaaf3d;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #4cdaaf;
				background-color: #4cdaaf;
			}
		}
		.progress-bar {
			background-color: #4cdaaf;
		}
	}
	&.hoan-thanh {
		.name-status {
			color: #27ae60;
			background-color: #27ae6036;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #27ae60;
				background-color: #27ae60;
			}
		}
		.progress-bar {
			background-color: #27ae60;
		}
	}
	&.da-khai-thac {
		.name-status {
			color: #365683;
			background-color: #36568338;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #365683;
				background-color: #365683;
			}
		}
		.progress-bar {
			background-color: #365683;
		}
	}
	&.dang-khai-thac {
		.name-status {
			color: #a32929;
			background-color: #a3292938;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #a32929;
				background-color: #a32929;
			}
		}
		.progress-bar {
			background-color: #a32929;
		}
	}
	&.dang-van-chuyen-ve-vn {
		.name-status {
			color: #b5cdef;
			background-color: #b5cdef3a;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #b5cdef;
				background-color: #b5cdef;
			}
		}
		.progress-bar {
			background-color: #b5cdef;
		}
	}

	&.da-tiep-nhan {
		.name-status {
			color: #f2994a;
			background-color: #f2994a36;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #f2994a;
				background-color: #f2994a;
			}
		}
		.progress-bar {
			background-color: #f2994a;
		}
	}
	&.dang-dong-thung {
		.name-status {
			color: #70bbfd;
			background-color: #70bbfd33;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #70bbfd;
				background-color: #70bbfd;
			}
		}
		.progress-bar {
			background-color: #70bbfd;
		}
	}
	&.cho-xuat-kho {
		.name-status {
			color: #ffcb00;
			background-color: #ffcb0033;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #ffcb00;
				background-color: #ffcb00;
			}
		}
		.progress-bar {
			background-color: #ffcb00;
		}
	}
	&.dang-giao-hang {
		.name-status {
			color: #4cdaaf;
			background-color: #4cdaaf36;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #4cdaaf;
				background-color: #4cdaaf;
			}
		}
		.progress-bar {
			background-color: #4cdaaf;
		}
	}
	&.da-dong-thung {
		.name-status {
			color: #4dacc8;
			background-color: #4dabc840;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #4dacc8;
				background-color: #4dacc8;
			}
		}
		.progress-bar {
			background-color: #4dacc8;
		}
	}
	&.dang-xu-ly-awb {
		.name-status {
			color: #2d346f;
			background-color: #2d346f3b;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #2d346f;
				background-color: #2d346f;
			}
		}
		.progress-bar {
			background-color: #2d346f;
		}
	}
	&.cho-nhap-kho {
		.name-status {
			color: #b573c7;
			background-color: #b573c73b;
			border-radius: 50px;
			padding: 6px 16px;
			span {
				color: #b573c7;
				background-color: #b573c7;
			}
		}
		.progress-bar {
			background-color: #b573c7;
		}
	}
}

.group-btn-search-tracking {
	button {
		i {
			font-size: 18px;
		}
	}
}

.tracking-and-image {
	position: relative;
	cursor: pointer;
	.icon-img {
		margin-right: 5px;
	}
	img {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		width: 100px;
		height: 100px;
		right: 100%;
		top: -20px;
	}
	&:hover {
		img {
			opacity: 1;
			visibility: visible;
		}
	}
	span {
		word-break: break-all;
	}
}

.note-tbl {
	position: relative;
	cursor: pointer;
	.content {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		overflow: scroll;
		width: -webkit-fill-available;
		max-height: 120px;
		object-fit: cover;
		border: 1px solid #eee;
		background-color: #fff;
		display: revert;
		left: 0;
		bottom: 100%;
		transform: translateX(-50%);
		white-space: break-spaces;
		z-index: 99;
		font-size: 12px;
	}
	&:hover {
		.content {
			opacity: 1;
			visibility: visible;
			overflow: scroll;

			img {
				border: 1px solid rgb(214, 208, 196);
				width: inherit;
			}
		}
	}
}

.popup-inner {
	&.view-order {
		label {
			margin-bottom: 10px;
		}
		.total-price-view {
			font-weight: bold;
			font-size: 30px;
		}
	}
}

.table-tracking {
	tbody {
		tr {
			td {
				&:nth-child(3) {
					max-width: 200px;
					width: 200px;
					text-wrap: wrap;
				}
			}
		}
	}
}

.custom-file-button {
	flex-direction: row !important;
	input[type="file"] {
		margin-left: -2px !important;
		&::-webkit-file-upload-button {
			display: none;
		}
		&::file-selector-button {
			display: none;
		}
	}
	&:hover {
		label {
			background-color: #2f80ed;
			cursor: pointer;
		}
	}
	label {
		margin-bottom: 0 !important;
		background-color: #2f80ed;
		color: #fff;
		border: 1px solid #2f80ed;
	}
}
.modal-delete-tracking {
	z-index: 10000;

	.modal-header {
		justify-content: center;
	}
	.modal-footer {
		margin-bottom: 20px;
		span {
			margin-right: 5px;
		}
		.btn-outline-warning {
			color: #000;
			i {
				line-height: 22px;
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 576px) {
	.wrap-filter {
		.list-button {
			flex-wrap: unset;
			display: -webkit-box;
			overflow: hidden;
			overflow-x: scroll;
			white-space: nowrap;
			margin-left: 0;
			button {
				width: auto;
				&:first-child {
					margin-left: 0;
				}
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar {
				height: 2px;
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #000000;
			}
		}
		.list-input {
			.col-left {
				margin-bottom: 10px;
			}
			.col-right {
				flex-direction: row;
				flex-wrap: wrap;
				.wrap-date-range {
					margin-right: 0;
					margin-bottom: 10px;
					width: 100% !important;
					text-align: left !important;
					max-width: unset;
					input {
						text-align: left !important;
						padding: 0px 14px;
					}
				}
				.list-time-select {
					margin-right: 0;
					margin-bottom: 10px;
					width: calc(100% - (30% + 10px)) !important;
					text-align: left !important;
					max-width: unset;
					input {
						text-align: left !important;
					}
				}
				.form-search-btn {
					width: 30%;
					display: flex;
					justify-content: flex-end;
					margin-left: 10px;
					button {
						min-height: 44px;
						min-width: 44px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.table-tracking-mobile {
		margin-bottom: 15px !important;
		.item-table {
			.btn-wrap-tracking-mobile {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				margin-left: -10px;
				margin-top: 10px;
				.btn {
					margin-bottom: 10px;
					margin-left: 10px;
					margin-right: 0;
					width: calc(50% - 10px);
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 35px;
					padding: 0px 5px 0px 20px;
				}
				.btn-create-order {
					background-color: #27ae60;
				}
				.btn-send-required {
					background-color: #2f80ed;
				}
				.btn-edit-tracking {
					background-color: #f2994a;
				}
				.btn-delete-tracking {
					background-color: #cc3333;
				}
				i {
					position: absolute !important;
					left: 10px;
					top: calc(50% - 2px) !important;
					transform: translateY(-50%);
				}
			}
			.item span {
				word-break: break-all;
			}
		}
	}

	.form-search-tracking-multi {
		min-width: unset;
		padding: 10px;
	}

	.filter-data-mobile {
		.result-total {
			width: 100%;
		}
	}

	.barcode-bill svg {
		width: 100%;
	}
}

.datapicker-range {
	width: 100%;
	margin-right: 24px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	border-radius: 4px;
	outline: none !important;
	position: relative;
}

.btn-create-tracking-many {
	background-color: #27ae60 !important;
}

.btn-delete-tracking-many {
	background-color: #d03050 !important;
}

.copy-icon:hover span {
	opacity: 1;
	visibility: visible;
}

.copy-icon:hover i {
	color: #927474;
}

.copy-icon i {
	color: #403737;
}

.copy-icon {
	cursor: pointer;
	margin: 2px;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	position: relative;

	.title-hover-copy {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		display: inline-block;
		top: -42px;
		right: 0;
		padding: 2px 6px;
		z-index: 99;
		background-color: #f8f8f8;
		color: black;
		width: max-content;
		border: 1px solid rgb(115, 100, 100);
		font-weight: 400 !important;
	}
}

.code-detail {
	color: #5369f8 !important;
	cursor: pointer;
}

.name-status-detail {
	height: 44px;
	border-radius: 50px;
	padding: 0px 16px !important;

	span {
		color: rgb(51, 204, 186);
		background-color: rgb(51, 204, 186);
	}
}

.title-view-detail {
	font-size: 30px;
	line-height: 44px;
}

.detail-tracking-wrapper {
	padding: 0px 48px;
	.box-view-wrapper {
		min-height: 300px;
		gap: 32px;
		border-radius: 5px;
		box-shadow:
			0px 1px 2px 0px #70bbfd1a,
			0px 4px 4px 0px #70bbfd17,
			0px 10px 6px 0px #70bbfd0d,
			0px 18px 7px 0px #70bbfd03,
			0px 28px 8px 0px #70bbfd00;

		.wrapper-title {
			border-radius: 5px 5px 0px 0px;
			background-color: #70bbfd33;
			padding: 14px 16px;
			width: 100%;
			min-height: 60px;

			.copy-tracking {
				font-size: 18px;
				line-height: 26px;
				height: max-content;
				margin-right: 8px;
			}

			.title-tracking {
				font-size: 24px;
				line-height: 32px;
				font-weight: 700;
				margin-right: 24px;
				text-wrap: nowrap;
			}

			.code-tracking {
				font-size: 20px;
				line-height: 32px;
				font-weight: 700;
				color: #2f80ed;
				word-break: break-all;
			}
		}

		.wrapper-body {
			width: 100%;
			padding: 32px 32px 32px 32px;
			gap: 32px;
			.code-tracking {
				color: #2f80ed;
				font-size: 16px;
				font-family: Roboto;
				font-weight: 700;
				line-height: 24px;
				word-wrap: break-word;
			}

			.text-normal {
				color: #111827;
				font-size: 16px;
				font-family: Roboto;
				font-weight: 700;
				line-height: 24px;
				word-wrap: break-word;
			}

			.img-wrapper {
				width: 56px;
				height: 56px;
				position: relative;
				border-radius: 100px;
				border: 1.5px white solid;
			}
		}
	}
}
