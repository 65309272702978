//
// email.scss
//

.email-container {
	// margin-bottom: $grid-gutter-width;
	background-color: $card-bg;
	.inbox-leftbar {
		padding: $card-spacer-x;
		width: 250px;
		float: left;
		// padding: 0 20px 20px 10px;
		padding-bottom: 184px;
		margin-bottom: -184px;
	}

	.inbox-rightbar {
		margin-left: 250px;
		border-left: 5px solid $body-bg;
		padding: 0 0 1.5rem 25px;
	}
}

.list-role-group {
	background-color: #f1f3f6d6;

	.head-code-number {
		background: rgba(112, 187, 253, 0.2);
		margin-top: 0px;
		font-weight: 600;
		box-sizing: border-box;
		border-radius: 4px;
	}

	.message-list {
		display: block;
		padding-left: 0;
		border-radius: 0.2rem;

		li {
			background: #fafcfe;
			min-height: 60px;
			list-style-type: none;
			cursor: default;
			transition-duration: 0.3s;
			margin-bottom: 1px;
			box-shadow: $box-shadow-sm;

			a {
				color: $gray-700;
			}

			&:hover {
				box-shadow: $box-shadow;
				transition-duration: 0.05s;
			}

			.col-mail {
				float: left;
				position: relative;
			}

			.col-mail-1 {
				width: 100%;

				.star-toggle,
				.checkbox-wrapper-mail,
				.dot {
					display: block;
					float: left;
				}

				.dot {
					border: 4px solid transparent;
					border-radius: 100px;
					margin: 22px 26px 0;
					height: 0;
					width: 0;
					line-height: 0;
					font-size: 0;
				}

				.star-toggle {
					color: $gray-500;
					margin-left: 10px;
				}
			}

			.col-mail-2 {
				position: absolute;
				top: 0;
				left: 320px;
				right: 0;
				bottom: 0;

				.subject,
				.date {
					position: absolute;
					top: 0;
				}

				.subject {
					left: 0;
					right: 110px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.date {
					right: 0;
					width: 100px;
					padding-left: 10px;
				}
			}
		}

		li.active,
		li.mail-selected {
			background: $gray-100;
			transition-duration: 0.05s;
		}

		li.active,
		li.active:hover {
			box-shadow: inset 3px 0 0 $cyan;
		}

		li.unread a {
			// font-weight: $font-weight-semibold;
			color: darken($dark, 5%);
		}

		.checkbox-wrapper-mail {
			cursor: pointer;
			height: 20px;
			width: 20px;
			position: relative;
			display: inline-block;
			box-shadow: inset 0 0 0 2px $gray-400;
			border-radius: 3px;

			input {
				opacity: 0;
				cursor: pointer;
			}

			input:checked ~ label {
				opacity: 1;
			}

			label {
				border-radius: 3px;
				position: absolute;
				top: 3px;
				left: 3px;
				right: 3px;
				bottom: 3px;
				cursor: pointer;
				background: rgb(19 169 83 / 65%);
				opacity: 0;
				margin-bottom: 0 !important;
				transition-duration: 0.05s;
			}

			label:active {
				background: #87949b;
			}
		}
	}
}

.mail-list {
	a {
		color: $gray-700;
		padding: 7px 10px;
		display: block;
	}
}

.reply-box {
	border: 2px solid $light;
}

@media (max-width: 648px) {
	.email-container {
		.inbox-leftbar {
			width: 100%;
			float: none;
			margin-bottom: 21px;
			padding-bottom: 1.25rem;
		}

		.inbox-rightbar {
			margin-left: 0;
			border: 0;
			padding-left: 0;
		}
	}
}

@media (max-width: 520px) {
	.inbox-rightbar {
		> .btn-group {
			margin-bottom: 10px;
		}
	}

	.message-list li {
		.col-mail-1 {
			width: 150px;

			.title {
				left: 80px;
			}
		}

		.col-mail-2 {
			left: 160px;

			.date {
				text-align: right;
				padding-right: 10px;
				padding-left: 20px;
			}
		}
	}
}

.chatbox {
	position: fixed;
	right: 35px;
	bottom: 10px;
	width: 300px;
	background-color: $card-bg;
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	z-index: 99;

	.chat-input {
		.form-control {
			padding-right: 60px;
		}
	}

	.chat-link {
		position: absolute;
		right: 4px;
		top: 10px;
	}
}

.label-business-logic {
	.form-check-label {
		margin-bottom: 0px !important;
	}
	.checkbox-wrapper-mail .toggle {
		border-radius: 3px;
		position: absolute;
		top: 3px;
		left: 3px;
		right: 3px;
		bottom: 3px;
		cursor: pointer;
		background: rgba(19, 169, 83, 0.65);
		opacity: 0;
		margin-bottom: 0 !important;
		transition-duration: 0.05s;
	}
}
