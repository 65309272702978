// for printing

@media print {
  .print-component {
    display: block;
    position: relative;
  }

  .print-component {
    height: 100%;
    .print-page {
      page-break-inside: avoid;
      break-inside: avoid;
      page-break-after: always;
      break-after: page;
      border: 2px solid #000;
      height: 100%;
    }
  }
}
